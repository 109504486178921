<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>等级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button type="primary" class="add" @click="dialogVisible = true"
      v-if="$store.state.powerList.indexOf('material:cate:list:add') !== -1"
    >
      添加分类
    </el-button>

		<div class="search_box">
			<span>分类名称：</span>
			<el-input
        style="width: 200px;"
				v-model="formData.cateName"
				placeholder="请输入分类名称"
			/>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
		</div>
    <el-table
			border
			ref="multipleTable"
			:data="tableData"
			tooltip-effect="dark"
			style="width: 100%"
			@selection-change="handleSelectionChange">
			<el-table-column
			type="selection"
			width="55">
			</el-table-column>
			<el-table-column label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
			</el-table-column>
			<el-table-column prop="cateId" label="分类ID">
			</el-table-column>
			<el-table-column prop="cateName" label="分类名称">
			</el-table-column>
			<el-table-column prop="strStatus" label="分类状态">
			</el-table-column>
			<el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            v-if="scope.row.status == 2 && $store.state.powerList.indexOf('material:cate:list:edit') !== -1"
            title="确认上架该产品吗？"
            @confirm="toModify(1, [scope.row])"
          >
            <el-button type="success" size="mini" slot="reference" class="popconfirm"> 上架 </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-if="scope.row.status == 1 && $store.state.powerList.indexOf('material:cate:list:edit') !== -1"
            title="确认下架该产品吗？"
            @confirm="toModify(2, [scope.row])"
          >
            <el-button type="warning" size="mini" slot="reference" class="popconfirm"> 下架 </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-if="$store.state.powerList.indexOf('material:cate:list:edit') !== -1"
            title="确认删除该产品吗？"
            @confirm="toModify(0, [scope.row])"
          >
            <el-button type="danger" size="mini" slot="reference"> 删除 </el-button>
          </el-popconfirm>
          <el-button type="primary" size="mini" style="margin-left: 0.625rem;" 
            @click="showEdit(scope.row)" 
            v-if="$store.state.powerList.indexOf('material:cate:list:add') !== -1"
          >
            编辑
          </el-button>
        </template>
			</el-table-column>
    </el-table>
		<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="formData.currentPage"
			:page-sizes="[5, 10, 15, 20]"
			:page-size="formData.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="formData.total"
		>
		</el-pagination>
    
    <div class="btn_box">
      <el-popconfirm
        title="确认删除该产品吗？"
        v-if="$store.state.powerList.indexOf('material:cate:list:edit') !== -1"
        @confirm="toModify(0, multipleSelection)"
      >
        <el-button type="primary" slot="reference">批量删除</el-button>
      </el-popconfirm>
      <el-popconfirm
        title="确认上架该产品吗？"
        v-if="$store.state.powerList.indexOf('material:cate:list:edit') !== -1"
        @confirm="toModify(1, multipleSelection)"
      >
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem;">批量上架</el-button>
      </el-popconfirm>
      <el-popconfirm
        title="确认下架该产品吗？"
        v-if="$store.state.powerList.indexOf('material:cate:list:edit') !== -1"
        @confirm="toModify(2, multipleSelection)"
      >
        <el-button type="primary" slot="reference" style="margin-left: 0.625rem;">批量下架</el-button>
      </el-popconfirm>
    </div>
		
		<el-dialog
		  title="添加分类"
		  :visible.sync="dialogVisible"
		  width="50%"
		  >
		    <el-form ref="form" label-width="100px" class="grade_list">
		      <el-form-item label="	分类名称">
		        <el-input placeholder="请输入分类名称" v-model="addForm.cateName" />
		      </el-form-item>
		    </el-form>
		    <span slot="footer" class="dialog-footer">
		      <el-button @click="hideVisible()">取 消</el-button>
		      <el-button type="primary" @click="toAdd()">确 定</el-button>
		    </span>
		</el-dialog>
    
		<el-dialog
		  title="编辑分类"
		  :visible.sync="dialogVisible2"
		  width="50%"
		  >
		    <el-form ref="form" label-width="100px" class="grade_list">
          <el-form-item label="	分类名称">
            <el-input placeholder="请输入分类名称" v-model="editForm.cateName" />
          </el-form-item>
		    </el-form>
		    <span slot="footer" class="dialog-footer">
		      <el-button @click="hideVisible()">取 消</el-button>
		      <el-button type="primary" @click="toEdit()">确 定</el-button>
		    </span>
		</el-dialog>
    
  </div>
</template>

<script>
import productBox from './productBox'
import { 
  cateList,
  editCate,
  cateModify,
  changeSort, 
} from "../../api/shopMaterial.js";
export default {
	name: "productList",
	components: {
		productBox
	},
  data() {
    return {
      editForm: {
        cateId: '',
        cateName: '',
      },
			formData: {
				cateName: '',
				total: 0,
				pageSize: 10,
				currentPage: 1,
			},
      addForm: {
        cateName: '',
        cateId: 0,
      },
			tableData: [],
			multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
    };
  },
  created() {
		this.getTableList(); // 获取表格数据
  },
  methods: {
    async getTableList() {
      const { data } = await cateList(this.formData);
			this.tableData = data.list;
			this.formData.total = data.pagination.total
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    async toAdd() {
      if (!this.addForm.cateName) {
        return this.$message.error('请填写分类名称！')
      }
      const { data } = await editCate(this.addForm);
      if (data.code == 0) {
        this.dialogVisible = false
        this.getTableList()
        this.$message.success(data.msg)
      } else {
        this.$message.error(data.msg)
      }
    },
    async toModify(status, list) {
      if (list.length < 1) {
        return this.$message.error('请选择需要操作的商品！')
      }
      let ids = list.map(item => {
        return item.cateId
      }).join(',')
      const { data } = await cateModify({status, ids});
      if (data.code == 0) {
        this.getTableList()
        this.$message.success(data.msg)
      } else {
        this.$message.error(data.msg)
      }
    },
    async showEdit(row) {
      this.editForm.cateId = row.cateId
      this.editForm.cateName = row.cateName
      this.dialogVisible2 = true
    },
    async toEdit() {
      const { data } = await editCate(this.editForm);
      console.log('编辑回调', data)
      if (data.code == 0) {
        this.dialogVisible2 = false
        this.getTableList()
        this.$message.success(data.msg)
      } else {
        this.$message.error(data.msg)
      }
    },
    // 开始修改排序
    onChangeSort(row) {
      row.inputVisible = true;
      this.tableData = [...this.tableData]
    },
    // 修改排序
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        cateId: row.cateId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    // 隐藏弹窗
    hideVisible () {
      this.dialogVisible = false
      this.dialogVisible2 = false
    },
		handleSelectionChange(arr) {
			this.multipleSelection = arr;
			console.log(this.multipleSelection)
		},
		handleSizeChange(num) {
			console.log(num)
      this.formData.pageSize = num;
			this.getTableList()
		},
		handleCurrentChange(num) {
			console.log('currentPage',num)
      this.formData.currentPage = num;
			this.getTableList()
		},
     onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  }
};
</script>

<style lang="less" scoped>
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #F1F1F1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #F1F1F1;
  }
  .tips {
    // text-align: right;
    color: orangered;
  }
  .grade_list {
    padding: 0 10px 10px;
    .grade_item {
      border-bottom: 1px solid #F1F1F1;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .form_item {
        display: flex;
        .el-form-item {
          flex: 1;
          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>