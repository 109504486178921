import request from '../utils/request'
import baseUrl from './baseUrl'

export const cateList = (data) => request({
  url: baseUrl + '/materialCate/selectForBack',
  method: 'POST',
  data
})

export const cateModify = (data) => request({
  url: baseUrl + '/materialCate/modify',
  method: 'POST',
  data
})
export const changeSort = (data) => request({
  url: baseUrl + '/materialCate/modifyDept',
  method: 'POST',
  data
})
export const editCate = (data) => request({
  url: baseUrl + '/materialCate/add',
  method: 'POST',
  data
})
export const cateAllApi = (data) => request({
  url: baseUrl + '/materialCate/selectAll',
  method: 'POST',
  data
})

// 素材列表
export const materialList = (data) => request({
  url: baseUrl + '/material/selectForBack',
  method: 'POST',
  data
})
export const materialDetail = (data) => request({
  url: baseUrl + '/material/selectForId',
  method: 'POST',
  data
})

export const materialAdd = (data) => request({
  url: baseUrl + '/material/add',
  method: 'POST',
  data
})

export const materialDelete = (data) => request({
  url: baseUrl + '/material/deleteById',
  method: 'POST',
  data
})
